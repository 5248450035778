<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>
          Détail du produit
          <span v-if="productPreview.isReady">
            : {{ productPreview.name }}
          </span>
        </h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <router-link class="btn btn-link btn-sm" to="/products" active-class="active">
              <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
            </router-link>
          </div>
        </div>
      </div>

      <async-object
        :should-fetch="true"
        :fetch-method="fetchProduct"
      >
        <!-- <preview :product="productPreview" /> -->
        <b-tabs content-class="w-100">
          <b-tab title="Produit" active class="mt-3 py-4">
            <template>
              <div class="row">
                <div class="col-12">
                  <div class="card mb-4">
                    <div class="card-header">Informations générales</div>
                    <div class="card-body">
                      <dl class="row">
                        <dt class="col-6">Boutique (Front)</dt>
                        <dd class="col-6">
                          <a v-if="store && store.slug && store.name" class="text-left" :href="storeAppLink" target="_blank">
                            <u>{{ store.name }}</u>
                          </a>
                          <span v-else class="text-danger">Indisponible</span>
                        </dd>
                      </dl>
                      <dl class="row">
                        <dt class="col-6">Boutique (Admin)</dt>
                        <dd class="col-6">
                          <router-link v-if="store && store.id" :to="{ name: 'stores.details', params: { id: store.id } }">
                            <u>Afficher</u>
                          </router-link>
                          <span v-else class="text-danger">Indisponible</span>
                        </dd>
                      </dl>
                      <dl class="row">
                        <dt class="col-6">Administrateur(s)</dt>
                        <dd class="col-6">
                          <router-link v-if="store && store.id" :to="{ name: 'users.index', query: { store: store.id } }">
                            <u>Afficher</u>
                          </router-link>
                          <span v-else class="text-danger">Indisponible</span>
                        </dd>
                      </dl>
                      <dl class="row">
                        <dt class="col-6">Code</dt>
                        <dd class="col-6">
                          <span v-if="store && store.code">
                            {{ store.code }}
                          </span>
                          <span v-else class="text-danger">Indisponible</span>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card mb-4">
                    <div class="card-header">Détail du produit</div>
                    <div class="card-body">
                      <dl class="row">
                        <dt class="col-6">Statut</dt>
                        <dd class="col-6">
                          <span>
                            {{ productStatus }}
                          </span>
                        </dd>
                      </dl>
                      <dl class="row">
                        <div class="col-12">
                          <v-dynamic-form
                            :fields="fields"
                            :initial-values="{
                              categoryId: productPreview.categoryId,
                              isSuspended: productPreview.isSuspended.toString()
                            }"
                            @change="handleChange"
                            :errors="formErrors"
                          ></v-dynamic-form>
                        </div>
                      </dl>
                      <dl class="row">
                        <div class="col-12">
                          <!-- <button type="button" class="btn btn-primary" @click="submit">Enregistrer</button> -->
                          <button-loader :disabled="isSaving" :loading="isSaving" @click="submit">
                            Enregistrer
                          </button-loader>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-tab>
          <b-tab title="Aperçu">
            <preview :product="productPreview" />
          </b-tab>
        </b-tabs>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import AsyncObject from '@/components/common/AsyncObject'
import { BTabs, BTab } from 'bootstrap-vue'
import config from '@/config'
import handleForm from '@/mixins/handle-form'
import useValidator from '@/mixins/useValidator'
import ButtonLoader from '@/components/common/ButtonLoader'
import Preview from './Preview.vue'

export default {
  components: {
    AsyncObject,
    Preview,
    'b-tabs': BTabs,
    'b-tab': BTab,
    ButtonLoader
  },
  mixins: [handleForm, useValidator],
  data () {
    return {
      isSaving: false,
      form: {
        categoryId: null,
        isSuspended: null
      }
    }
  },
  computed: {
    formErrors () {
      return this.errors
    },
    fields () {
      return [
        {
          name: 'categoryId',
          label: 'Catégorie',
          type: 'product-category-selector',
          required: true
        },
        {
          name: 'isSuspended',
          label: 'Produit suspendu',
          type: 'select',
          options: [
            {
              value: 'true',
              label: 'Oui'
            },
            {
              value: 'false',
              label: 'Non'
            }
          ],
          required: true
        }
      ]
    },
    validationRules () {
      return {
        categoryId: 'required'
      }
    },
    store () {
      return this.$store.state.productEditor.store
    },
    storeAppLink () {
      if (!this.store) {
        return ''
      }

      return `${config.app.frontUrl}/boutique/${this.store.slug}`
    },
    productPreview () {
      let storeName = 'Ma boutique'

      if (this.currentStore && this.currentStore.name) {
        storeName = this.currentStore.name
      }

      if (this.$store.state.productEditor.store && this.$store.state.productEditor.store.name) {
        storeName = this.$store.state.productEditor.store.name
      }

      return {
        ...this.$store.state.productEditor,
        storeName
      }
    },
    productStatus () {
      if (!this.productPreview) {
        return ''
      }

      switch (this.productPreview.status) {
        case 'draft':
          return 'Brouillon'
        case 'published':
          return 'Publié'
        case 'archived':
          return 'Archivé'
        default:
          return ''
      }
    }
  },
  methods: {
    handleChange (values) {
      this.form = { ...values }

      if (values.isSuspended) {
        this.form.isSuspended = this.form.isSuspended === 'true'
      }
    },
    fetchProduct () {
      return this.$store.dispatch('productEditor/initEditForm', this.$route.params.id)
    },
    async submit () {
      this.isSaving = true

      try {
        this.errors = {}

        const validationRules = this.validationRules

        const validate = this.validate(this.form, validationRules)

        if (validate) {
          await this.$store.dispatch('products/patchProducts', { products: [this.$route.params.id], ...this.form })
          this.$toasted.success('Produit(s) mis à jour avec succès.')
          return
        }

        this.$toasted.error('Vous devez remplire le formulaire.')
      } catch (error) {
        this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>
